import React, { useState } from 'react';
import './voting.css';
import YoutubeEmbed from '../components/YoutubeEmbed';
import scrapbook from '../assets/SAM IMG.jpg';
import remembering from '../assets/BEN IMG.jpg';
import linings from '../assets/MATT IMG.jpg';
import summer from '../assets/SEB IMG.jpg';
import award from '../assets/oscar-hbotc.png';
import insta from '../assets/insta.png';
import twitter from '../assets/twitter.png';
import facebook from '../assets/facebook.png';
import tiktok from '../assets/tiktok.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCP_V1l1q4b3ZgOBdrJaqigqFgXkrQcIVE",
  authDomain: "hbotc-e5ee9.firebaseapp.com",
  projectId: "hbotc-e5ee9",
  storageBucket: "hbotc-e5ee9.appspot.com",
  messagingSenderId: "1015637012183",
  appId: "1:1015637012183:web:42bb38483ab366f5a8ca92"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

const Voting = () => {

  const [votes, setVotes] = useState({
    actor: '',
  });

  const handleVote = (event) => {
    const target = event.target;
    const value = target.value;

    db.collection('votes')
      .doc(value)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const count = data.count + 1;
          db.collection('votes').doc(value).update({ count });
        } else {
          db.collection('votes').doc(value).set({ count: 1 });
        }
      })
      .catch((error) => {
        console.error('Error submitting vote: ', error);
      });

    setVotes({ actor: value });
  };

  const [isClicked, setIsClicked] = useState(false);

  function handleClick() {
    setIsClicked(true);
    window.scrollTo(0,document.body.scrollHeight);
  }

  return (
    <div className="voting__main stars__bg">
      <div className="voting__container">
            <div className="voting__header">
              <h1 className="voting__songTitle gradient__font">heartbreak of the century</h1>
              <div className="voting__video">
                <YoutubeEmbed embedId="LupNFdlGOks" />
              </div>
              <p>Watch the new video then cast your vote for best actor:</p>
        </div>
        <div className="voting__nominees">
            <div className="voting__entry">
                <div className="voting__entry-img">
                  <img src={scrapbook} alt=""></img>
                </div>
                <div className="voting__entry-txt gradient__font">
                  <h2>best actor</h2>
                  <h1>sam bowden</h1>
                  <input
                    type="radio"
                    name="actor"
                    value="scrapbook"
                    checked={votes.actor === 'scrapbook'}
                    onChange={handleVote}
                  />
                </div>
            </div>
        </div>
        <div className="voting__nominees">
            <div className="voting__entry">
                <div className="voting__entry-img">
                  <img src={remembering} alt=""></img>
                </div>
                <div className="voting__entry-txt gradient__font">
                  <h2>best actor</h2>
                  <h1>ben barlow</h1>
                  <input
                    type="radio"
                    name="actor"
                    value="remembering"
                    checked={votes.actor === 'remembering'}
                    onChange={handleVote}
                  />
                </div>
            </div>
        </div>
        <div className="voting__nominees">
            <div className="voting__entry">
                <div className="voting__entry-img">
                  <img src={linings} alt=""></img>
                </div>
                <div className="voting__entry-txt gradient__font">
                  <h2>best actor</h2>
                  <h1>matt west</h1>
                  <input
                    type="radio"
                    name="actor"
                    value="linings"
                    checked={votes.actor === 'linings'}
                    onChange={handleVote}
                  />
                </div>
            </div>
        </div><div className="voting__nominees">
            <div className="voting__entry">
                <div className="voting__entry-img">
                  <img src={summer} alt=""></img>
                </div>
                <div className="voting__entry-txt gradient__font">
                  <h2>best actor</h2>
                  <h1>seb barlow</h1>
                  <input
                    type="radio"
                    name="actor"
                    value="summer"
                    checked={votes.actor === 'summer'}
                    onChange={handleVote}
                  />
                </div>
            </div>
        </div>
        <div className="voting__submit button__lg">
            <button onClick={handleClick} disabled={isClicked}>Submit your vote</button>
            {isClicked &&
                <div className="voting__thanks">
                  <div className="voting__thanks-header gradient__font">
                    <h1>thank you</h1>
                    <h2>screenshot, share, and tag us for a chance to win a merch bundle!</h2>
                    <div className="voting__thanks-award">
                      <img src={award} alt=""></img>
                    </div>
                    <h2>follow us for the winner announcement</h2>
                    <ul>
                      <li>
                        <a href="https://www.tiktok.com/@neckdeepuk">
                          <img src={tiktok} alt=""></img>
                        </a>
                      </li>
                      <li>
                        <a href="http://facebook.com/neckdeepuk/">
                        <img src={facebook} alt=""></img>
                        </a>
                      </li>
                      <li>
                        <a href="http://twitter.com/neckdeepuk">
                        <img src={twitter} alt=""></img>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/neckdeepuk/">
                        <img src={insta} alt=""></img>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              }
        </div>
      </div>
      <div>
    </div>
    </div>
  )
}

export default Voting
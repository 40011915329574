export const authEndpoint = "https://accounts.spotify.com/authorize";
const clientId = "545e983540f24dbea5077c380ca92251";
const redirectUri = "https://heartbreak.neckdeepuk.com/";

const scopes = [
  "user-follow-modify",
  "user-library-modify"
];

export const getTokenFromUrl = () => {
  return window.location.hash
    .substring(1)
    .split('&')
    .reduce((initial, item) => {
      var parts = item.split("=");
      initial[parts[0]] = decodeURIComponent(parts[1]);

      return initial;
    }, {});
};

export const loginUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}&response_type=token&show_dialog=true`;
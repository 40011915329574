import React from 'react'
import './login.css';
import spotifyIcon from '../assets/Spotify_Icon_RGB_White.png';
import { loginUrl } from "../spotify";

const Login = () => {
  return (
    <div className="login__main stars__bg">
        <div>
          <h1 className="login__songTitle gradient__font">heartbreak of the century</h1>
          <div className="login__florals">
            <h2 className="login__awardsVoting gradient__font">awards <br></br> voting</h2>
          </div>
        </div>
        <div className="login__connectDetails button__lg">
          <p>This experience will follow Neck Deep and save music to your Spotify library.</p>
          <a href={loginUrl}><button><img src={spotifyIcon} alt="spotify"></img>connect to vote</button></a>
        </div>
    </div>
  )
}

export default Login;
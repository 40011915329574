import React, { useEffect } from 'react';
import './App.css';
import Login from './pages/Login';
import Voting from './pages/Voting';
import { getTokenFromUrl } from './spotify';
import SpotifyWebApi from 'spotify-web-api-js';
import { useDataLayerValue } from './DataLayer';

const spotify = new SpotifyWebApi();

function App() {
  const [{ token }, dispatch] = useDataLayerValue();

  useEffect(() => {
    const hash = getTokenFromUrl();
    window.location.hash = "";
    const _token = hash.access_token;

    if (_token) {
      dispatch({
        type: 'SET_TOKEN',
        token: _token
      })

      spotify.setAccessToken(_token);
      spotify.followArtists(['2TM0qnbJH4QPhGMCdPt7fH']);
      spotify.addToMySavedTracks(['6X39BP7h0IGcDFnf0PHHOb']);
    }
  });

  return (
    <div className="app">
      {
        token ? (
          <Voting />
        ) : (
          <Login />
        )
      }
    </div>
  );
}

export default App;